import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { NgxSpinnerService } from "ngx-spinner";
import { HttpService } from '../../../services/http/http-service.service';
import { environment } from '../../../../environments/environment.prod';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.css']
})
export class InfoComponent implements OnInit {
  @Input() bshowInfo: boolean = false;
  //@Input() dataSelected: any = [];
  @Output() bshowValidator: EventEmitter <boolean> = new EventEmitter <boolean>();
  @Output() bshowSearch: EventEmitter <boolean> = new EventEmitter <boolean>();
  _dataSel: any = [];

  @Input()
        set dataSelected(data : string ){
            this._dataSel = data;
        }

  id: string = '';
  private sub: any;
  bhideSearch: boolean = false;
  bcolorSpinner: string = '';
  bshowData: boolean = false;

  constructor(private route: ActivatedRoute, private router: Router, private spinner: NgxSpinnerService, private httpService: HttpService) { }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {
      this.id = params['id']; 
      if (typeof this.id !== 'undefined')
      {
        this.bshowInfo = true;
        this.bshowData = true;
        this.showCertifiedData();
      } 
    });
  }

  showValidator() {
    this.bshowValidator.emit(true);
  }

  showSearch() {
    this.bshowSearch.emit(true);
  }

  showCertifiedData() {
    this.httpService.showCertifiedByNumber(this.id).subscribe(
      data => {
        if (data.data.hasOwnProperty('certified')) {
          this._dataSel = data.data.certified[0];

          console.log(this._dataSel.picture_path);
          if(this._dataSel.picture_path === '' || this._dataSel.picture_path === 'NULL') {
            this._dataSel.picture_path = environment.pathimage+'student.png';
          } else {
            this._dataSel.picture_path = environment.pathimages + this._dataSel.picture_path;
          }
          
          this.bcolorSpinner = '#57c0e8';
          this.showSpinner();
        }
      },
      error => {
        if (error.status == 401) {
        } else {
          console.log('error showCertifiedData content');
          console.log(error);
        }
      }
    );
  }

  showSpinner() {
    this.spinner.show(undefined, {
      bdColor:"rgba(51,51,51,0.8)",
      size:"medium",
      type:"timer",
      color: this.bcolorSpinner,
    });
    setTimeout(() => {
      /** spinner ends after 5 seconds */
      this.spinner.hide();
    }, 2000);
  }

}

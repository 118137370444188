
<div *ngIf="bshowInfo==true" class="row row_results">
    <div class="col-12">
        <h2 class="title">INFORMACIÓN DEL CERTIFICADO {{_dataSel.code}}</h2>
    </div>
    <div *ngIf="bshowData === false " class="col-lg-12 col-md-6 col-xs-12 text-right">
        <a (click)="showValidator()" class="btn btn-all-courses" href="#">Hacer otra búsqueda</a>
        <a (click)="showSearch()" class="btn btn-all-courses" href="#">Ver todos los resultados</a>
    </div>
    <div class="col-lg-4 col-md-6 col-xs-12">
        <div class="form-group">
            <img width="200" height="200" src="{{_dataSel.picture_path}}" class="attachment-medium size-medium" alt="" loading="lazy" srcset="{{_dataSel.picture_path}} 300w, {{_dataSel.picture_path}} 150w" sizes="(max-width: 150px) 100vw, 150px">
        </div>
        <div class="form-group">
            <h3>{{_dataSel.name}} {{_dataSel.lastname}} {{_dataSel.surname}}</h3>
        </div>
        <div class="form-group">
            <i class="fa fa-hashtag" aria-hidden="true"></i> {{_dataSel.student_code}}
        </div>
    </div>
    <div class="col-lg-8 col-md-6 col-xs-12">
        <div id="datos" class="form-group">
        <ul class="list-group list-group-flush">
            <li class="list-group-item">
                <i class="fa fa-certificate" aria-hidden="true"></i> {{_dataSel.course_name}}
            </li>
            <li class="list-group-item">
                <i class="fa fa-graduation-cap" aria-hidden="true"></i> {{_dataSel.schoolclass_name}}
            </li>
            <li class="list-group-item">
                <i class="fa fa-calendar" aria-hidden="true"></i> FECHA DE INICIO: {{_dataSel.start_date}}
            </li>
            <li class="list-group-item">
                <i class="fa fa-calendar" aria-hidden="true"></i> FECHA DE TERMINACIÓN: {{_dataSel.end_date}}
            </li>
            <li class="list-group-item">
                <i class="fa fa-percent" aria-hidden="true"></i> ACREDITACIÓN: {{_dataSel.mark}}
            </li>
            <li class="list-group-item">
                <i class="fa fa-clock-o" aria-hidden="true"></i> HORAS: {{_dataSel.hours}}
            </li>
        </ul>
        </div>
    </div>
</div>
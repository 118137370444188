<header id="masthead" class="site-header">
    <div class="header-default">
        <div class="container">
            <div class="row">
                <div class="col-md-5 col-lg-4">
                    <div class="site-branding">

                        <div class="site-logo">
                        <a href="https://www.tr3spsicologia.centelica.com/" class="custom-logo-link" rel="home"><img width="550" height="227" src="https://www.tr3spsicologia.centelica.com/wp-content/uploads/2021/04/logo_tr3s_color.png" class="custom-logo" alt="tr3s psicología y sexología" srcset="https://www.tr3spsicologia.centelica.com/wp-content/uploads/2021/04/logo_tr3s_color.png 550w, https://www.tr3spsicologia.centelica.com/wp-content/uploads/2021/04/logo_tr3s_color-300x124.png 300w" sizes="(max-width: 550px) 100vw, 550px" /></a>            </div>

                        <div>
                        <p class="site-title"><a href="https://www.tr3spsicologia.centelica.com/"
                        rel="home">tr3s psicología y sexología</a></p>
                        </div>

                    </div><!-- .site-branding -->
                </div>

                <div class="col-lg-8 pull-right">
                    <a href="#" class="mobile-menu" id="mobile-open"><span></span></a>
                    <nav id="site-navigation" class="main-navigation">
                        <div class="menu-main-menu-container"><ul id="primary-menu" class="menu"><li id="menu-item-513" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home menu-item-513"><a href="https://www.tr3spsicologia.centelica.com/">Inicio</a></li>
                        <li id="menu-item-510" class="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-505 current_page_item menu-item-510"><a href="https://www.tr3spsicologia.centelica.com/about-us/" aria-current="page">Quiénes Somos</a></li>
                        <li id="menu-item-753" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-753"><a href="https://www.tr3spsicologia.centelica.com/servicios/">Servicios</a></li>
                        <li id="menu-item-715" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-715"><a href="#">ÁREA EDUCATIVA</a>
                        <ul class="sub-menu">
                        <li id="menu-item-780" class="menu-item menu-item-type-taxonomy menu-item-object-course_category menu-item-780"><a href="https://www.tr3spsicologia.centelica.com/course-category/cursos/">CURSOS</a></li>
                        <li id="menu-item-781" class="menu-item menu-item-type-taxonomy menu-item-object-course_category menu-item-781"><a href="https://www.tr3spsicologia.centelica.com/course-category/diplomados/">DIPLOMADOS</a></li>
                        <li id="menu-item-810" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-810"><a href="https://www.tr3spsicologia.centelica.com/proximamente/">TALLERES Y PLÁTICAS</a></li>
                        <li id="menu-item-811" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-811"><a href="https://www.tr3spsicologia.centelica.com/proximamente/">MATERIAL EDUCATIVO</a></li>
                        <li id="menu-item-655" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-655"><a href="/proximamente/">EGRESADOS</a></li>
                        </ul>
                        </li>
                        <li id="menu-item-508" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-508"><a href="https://www.tr3spsicologia.centelica.com/contact-us/">Contacto</a></li>
                        </ul></div>        
                    </nav><!-- #site-navigation -->
                </div>
            </div>
        </div>
    </div>
</header><!-- #masthead -->

<div class="nav-form ">
    <div class="nav-content">
        <div class="nav-spec">
            <nav class="nav-menu">
                <div class="mobile-menu nav-is-visible"><span></span></div>
                <div class="menu-main-menu-container"><ul id="primary-menu" class="menu"><li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home menu-item-513"><a href="https://www.tr3spsicologia.centelica.com/">Inicio</a><span class="arrow"></span></li>
                <li class="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-505 current_page_item menu-item-510"><a href="https://www.tr3spsicologia.centelica.com/about-us/" aria-current="page">Quiénes Somos</a><span class="arrow"></span></li>
                <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-753"><a href="https://www.tr3spsicologia.centelica.com/servicios/">Servicios</a><span class="arrow"></span></li>
                <li class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-715"><a href="#">ÁREA EDUCATIVA</a><span class="arrow"></span>
                <ul class="sub-menu">
                <li class="menu-item menu-item-type-taxonomy menu-item-object-course_category menu-item-780"><a href="https://www.tr3spsicologia.centelica.com/course-category/cursos/">CURSOS</a><span class="arrow"></span></li>
                <li class="menu-item menu-item-type-taxonomy menu-item-object-course_category menu-item-781"><a href="https://www.tr3spsicologia.centelica.com/course-category/diplomados/">DIPLOMADOS</a><span class="arrow"></span></li>
                <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-810"><a href="https://www.tr3spsicologia.centelica.com/proximamente/">TALLERES Y PLÁTICAS</a><span class="arrow"></span></li>
                <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-811"><a href="https://www.tr3spsicologia.centelica.com/proximamente/">MATERIAL EDUCATIVO</a><span class="arrow"></span></li>
                <li class="menu-item menu-item-type-custom menu-item-object-custom menu-item-655"><a href="/proximamente/">EGRESADOS</a><span class="arrow"></span></li>
                </ul>
                </li>
                <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-508"><a href="https://www.tr3spsicologia.centelica.com/contact-us/">Contacto</a><span class="arrow"></span></li>
                </ul></div>                
            </nav>
        </div>
    </div>
</div>

<script type='text/javascript' id='education-lms-custom-js-extra'>
/* <![CDATA[ */
var Education_LMS = {"menu_sidebar":"left","sticky_header":"0"};
/* ]]> */
</script>
<script type='text/javascript' src='../../../../assets/js/custom.js' id='education-lms-custom-js'></script>
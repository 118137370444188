import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {
  @Input() bshowSearch: boolean = false;
  @Input() dataValue: any = [];
  @Output() bshowInfo: EventEmitter <any> = new EventEmitter <any>();
  @Output() bshowValidator: EventEmitter <boolean> = new EventEmitter <boolean>();
  
  constructor() {
   }

  ngOnInit(): void {
  }

  onShowInfo(certified:any){
    this.bshowInfo.emit({event:true, dataSelected:certified});
  }

  showValidator() {
    this.bshowValidator.emit(true);
  }

}

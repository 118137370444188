<footer id="colophon" class="site-footer">
<div id="footer" class="container">
    <div class="footer-widgets">
        <div class="row">
            <div class="col-md-3">
                <div id="text-2" class="widget widget_text">			<div class="textwidget"><p><img class="alignnone size-full wp-image-237" src="https://www.tr3spsicologiaysexologia.com.mx/wp-content/uploads/2021/04/logo_tr3s_blanco.png" alt="" width="140" /></p>
                <p><span style="font-weight: 400;">Somos un equipo de profesionistas de la Psicología y la Sexología, enfocados al desarrollo integral de las personas.</span></p>
                </div>
                </div>                    
            </div>
            <div class="col-md-3">
                <div id="custom_html-2" class="widget_text widget widget_custom_html"><h3 class="widget-title"><span>Contacto</span></h3><div class="textwidget custom-html-widget"><div class="contact-info-box">
                    <div class="contact-info-item">
                        <div class="contact-text"><i class="fa fa-map-marker"></i></div>
                        <div class="contact-value">Avenida José María Pino Suárez 819, Local 11, Colonia Libertad, 22400<br>
                        Tijuana, Baja California, México
                        </div>
                    </div>
                    <div class="contact-info-item">
                    <div class="contact-text"><i class="fa fa-phone"></i></div>
                    <div class="contact-value">(664) 126 2613</div>
                    </div>
                    <div class="contact-info-item">
                    <div class="contact-text"><i class="fa fa-phone"></i></div>
                    <div class="contact-value">(664) 125 3136</div>
                    </div>
                    <div class="contact-info-item">
                    <div class="contact-text"><i class="fa fa-envelope"></i></div>
                    <div class="contact-value"><a href="tres3psicologia@gmail.com">tres3psicologia@gmail.com</a></div>
                    </div>	
                    </div>
                    </div>
                </div>                    
            </div>
            <div class="col-md-3">
            <div id="nav_menu-2" class="widget widget_nav_menu"><h3 class="widget-title"><span>Ligas</span></h3><div class="menu-footer-container"><ul id="menu-footer" class="menu"><li id="menu-item-502" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home menu-item-502"><a href="https://www.tr3spsicologiaysexologia.com.mx/">Inicio</a></li>
            <li id="menu-item-643" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-643"><a href="https://tr3spsicologiaysexologia.com.mx/course-category/cursos/">Cursos</a></li>
            <li id="menu-item-644" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-644"><a href="https://tr3spsicologiaysexologia.com.mx/course-category/diplomados/">Diplomados</a></li>
            <li id="menu-item-645" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-645"><a href="https://tr3spsicologiaysexologia.com.mx/platicas/">Pláticas</a></li>
            </ul></div></div>                    </div>
            <div class="col-md-3">
            <div id="custom_html-3" class="widget_text widget widget_custom_html"><h3 class="widget-title"><span>Horarios</span></h3><div class="textwidget custom-html-widget"><div class="contact-info-box">
            <p>Consultas solo con previa cita</p>
            <ul class="hours">
            <li>De 10:00 am a 5:00 pm</li>
            </ul>
            <p>Para agendar tu cita en línea visita <a href="https://www.doctoralia.com.mx/judith-celis/sexologo-psicologo/tijuana">Doctoralia</a></p>	

            </div>
            </div>
            </div>                    
            </div>
        </div>
    </div>

    <div class="copyright-area">
        <div class="row">
            <div class="col-sm-12">
                <div class="site-info">
                    Copyright. Todos los Derechos Reservados @{{yearPage}}- TR3S PSICOLOGÍA Y SEXOLOGÍA, Powered by <a href="https://www.centelica.com">Centelica</a>                        
                </div><!-- .site-info -->
            </div>
        </div>
    </div>

</div>
</footer><!-- #colophon -->

import { Injectable } from '@angular/core';
import {Http, Response, Headers, RequestOptions} from "@angular/http";
import { Observable } from 'rxjs';
import { map } from "rxjs/operators";
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(private http:Http) { }

  showCategories() {
    let token = localStorage.getItem("token");
    let headers = new Headers({'Authorization': 'Bearer ' + token});
    let options = new RequestOptions({ headers: headers });
    let formDataComponente = new FormData();
      
    return this.http.post(environment.pathapi + 'showcategories', formDataComponente, options).pipe(map((res: Response) => res.json())); 
  }

  showCertifiedFrontEnd(component:any=[]) {
    let formDataComponente = new FormData();
    formDataComponente.append('name', component.controls['certified_query'].value);
    formDataComponente.append('categories', component.controls['course'].value);
      
    return this.http.post(environment.pathapi + 'showcertifiedfrontend', formDataComponente).pipe(map((res: Response) => res.json())); 
  }

  showCertifiedByNumber(id:any=[]) {
    let formDataComponente = new FormData();
    formDataComponente.append('certified', id);
      
    return this.http.post(environment.pathapi + 'showcertifiedbynumber', formDataComponente).pipe(map((res: Response) => res.json())); 
  }

}

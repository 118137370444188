<div id="content" class="site-content">	
    <div id="primary" class="content-area">
		<div class="container">
            <div class="row">
                <main id="main" class="site-main col-md-12">

                </main>
            </div>
            
        </div>
    </div>

</div><!-- #content -->

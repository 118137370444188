import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SiteLayoutComponent } from './components/student/site-layout/site-layout.component';
import { InfoComponent } from './components/student/info/info.component';
import { CertifiedComponent } from './components/student/certified/certified.component';
import { ExternalUrlComponent } from './components/student/external-url/external-url.component';

const routes: Routes = [
  {
    path:'',
    component:SiteLayoutComponent,
    children:[
      { path: '', redirectTo: '/certified', pathMatch:'full'  },
      { path: 'certified', component:CertifiedComponent},
      { path: 'info/:id', component:InfoComponent}
    ]
  },
  { path: '**', component: ExternalUrlComponent }
   
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

<div *ngIf="bshowSearch==true" class="row row_results">
    <div class="col-12">
        <h2 class="title">RESULTADO DE BÚSQUEDA</h2>
        <div class="col-lg-12 col-md-6 col-xs-12 text-right">
            <a (click)="showValidator()" class="btn btn-all-courses" href="#">Hacer otra búsqueda</a>
        </div>
        <table *ngIf="dataValue.length>0" class="table table-striped">
            <thead>
                <tr>
                    <th scope="col">CERTIFICADO</th>
                    <th scope="col">ALUMNO</th>
                    <th scope="col">CURSO</th>
                    <th scope="col"></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let cert of dataValue">
                    <th scope="row">{{cert.code}}</th>
                    <td>{{cert.name}} {{cert.lastname}} {{cert.surname}}</td>
                    <td>{{cert.course_name}}</td>
                    <td>
                        <button (click)="onShowInfo(cert)" id="payment-button" type="button" class="btn btn-sm btn-validator">
                            <span id="payment-button-amount">VER MÁS</span>&nbsp;
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>

        <div *ngIf="dataValue.length === 0" class="row">
            <div class="col-12">
                <h2>No se encontró información</h2>
            </div>
        </div>
    </div>
</div>
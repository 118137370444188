import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { NgxSpinnerService } from "ngx-spinner";
import { HttpService } from '../../../services/http/http-service.service';
import { environment } from '../../../../environments/environment.prod';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.css']
})

export class ContentComponent implements OnInit {
  bshowSearch: boolean = false;
  bshowInfo: any = [];
  bhideSearch: boolean = false;
  bcolorSpinner: string = '#fff';
  listCertifieds: any = [];
  listCategories: any = [];
  dataSelected: any = [];

  formSearch = new FormGroup({
    certified_query: new FormControl('', Validators.required),
    course: new FormArray([])
  });

  constructor(private fb: FormBuilder, private router: Router, private spinner: NgxSpinnerService, private httpService: HttpService) {
    
  }

  ngOnInit(): void {
    this.showCategories();
    
  }

  onSubmit() {
    this.bhideSearch = true;
    this.bshowSearch = true;
    this.bshowInfo = [];
    this.bcolorSpinner = '#9363cc';
    this.showSpinner();

    this.httpService.showCertifiedFrontEnd(this.formSearch).subscribe(
      data => {
        if (data.data.hasOwnProperty('certifieds')) {
          this.listCertifieds = data.data.certifieds;
        }
      },
      error => {
        if (error.status == 401) {
        } else {
          console.log('error showCourses admin-config');
          console.log(error);
        }
      }
    );
  }

  showCategories() {
    this.httpService.showCategories().subscribe(
      data => {
        if (data.data.hasOwnProperty('categories')) {
          this.listCategories = data.data.categories;
        }
      },
      error => {
        if (error.status == 401) {
        } else {
          console.log('error showCategories component');
          console.log(error);
        }
      }
    );
  }

  onCheckboxChange(event:any) {
    const course: FormArray = this.formSearch.get('course') as FormArray;

    if (event.target.checked) {
      course.push(new FormControl(event.target.value));
    } else {
      let i: number = 0;
      course.controls.forEach((item) => {
        if (item.value == event.target.value) {
          course.removeAt(i);
          return;
        }
        i++;
      });
    }
  }

  showInfo(event:any) {
    if (event.event == true) {
      this.bhideSearch = true;
      this.bshowInfo = true;
      if(event.dataSelected.picture_path === '' || event.dataSelected.picture_path === 'NULL') {
        event.dataSelected.picture_path = '../../../../assets/images/student.png';
      } else {

        event.dataSelected.picture_path = environment.pathimages + event.dataSelected.picture_path;
      }
      this.dataSelected = event.dataSelected;
      this.bshowSearch = false;
      this.bcolorSpinner = '#57c0e8';
      this.showSpinner();
    }
  }

  showValidator(event=false) {
    if (event == true) {
      console.log('show validator');
      this.bhideSearch = false;
      this.bshowInfo = false;
      this.bshowSearch = false;
      this.bcolorSpinner = '#ff7e2e';
      this.showSpinner();
      this.clearData();
    }
  }

  clearData() {
    const course: FormArray = this.formSearch.get('course') as FormArray;
    this.formSearch.reset();

    this.formSearch.controls['certified_query'].setValue('');
    while (course.length !== 0) {
      course.removeAt(0);
    }
  }

  showSearch(event=false) {
    if (event == true) {
      this.bhideSearch = true;
      this.bshowInfo = false;
      this.bshowSearch = true;
      this.showSpinner();
    }
  }

  showSpinner() {
    this.spinner.show(undefined, {
      bdColor:"rgba(51,51,51,0.8)",
      size:"medium",
      type:"timer",
      color: this.bcolorSpinner,
    });
    setTimeout(() => {
      /** spinner ends after 5 seconds */
      this.spinner.hide();
    }, 2000);
  }

}

import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HttpModule } from '@angular/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/student/header/header.component';
import { ContentComponent } from './components/student/content/content.component';
import { FooterComponent } from './components/student/footer/footer.component';
import { SiteLayoutComponent } from './components/student/site-layout/site-layout.component';
import { NavbarComponent } from './components/student/navbar/navbar.component';
import { TitlebarComponent } from './components/student/titlebar/titlebar.component';
import { SearchComponent } from './components/student/search/search.component';
import { InfoComponent } from './components/student/info/info.component';

// Import library module
import { NgxSpinnerModule } from "ngx-spinner";
import { CertifiedComponent } from './components/student/certified/certified.component';
import { ExternalUrlComponent } from './components/student/external-url/external-url.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    ContentComponent,
    FooterComponent,
    SiteLayoutComponent,
    NavbarComponent,
    TitlebarComponent,
    SearchComponent,
    InfoComponent,
    CertifiedComponent,
    ExternalUrlComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    HttpModule
  ],
  providers: [],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }

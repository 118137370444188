<div *ngIf="bhideSearch==false">
    <h2 class="title">VALIDADOR DE CERTIFICADOS</h2>
    <div class="row">
        <div class="col-12">
            <form [formGroup]="formSearch" (ngSubmit)="onSubmit()">
                <div class="form-group">
                    <label class="control-label mb-1">Nombre o matrícula del estudiante</label>
                    <input formControlName="certified_query" id="certified_query" type="text" class="form-control" aria-required="true" aria-invalid="false">
                </div>
                <div class="form-group">
                    <div class="col-12">
                        <label *ngFor="let cat of listCategories" for="checkbox1" class="form-check-label check-margin">
                            <input type="checkbox" value="{{cat.id}}" class="form-check-input" (change)="onCheckboxChange($event)">{{cat.name}}
                        </label>
                    </div>
                </div>
                
                <div class="form-group">
                    <button id="payment-button" type="submit" [disabled]="!formSearch.valid" class="btn btn-sm btn-validator">
                        <span id="payment-button-amount">BUSCAR</span>&nbsp;
                        <i class="fa fa-search" aria-hidden="true"></i>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
<app-search [bshowSearch]="bshowSearch" [dataValue]="listCertifieds" (bshowInfo)="showInfo($event)" (bshowValidator)="showValidator($event)"></app-search>
<app-info [bshowInfo]="bshowInfo" [dataSelected]="dataSelected" (bshowSearch)="showSearch($event)" (bshowValidator)="showValidator($event)"></app-info>
<ngx-spinner></ngx-spinner>
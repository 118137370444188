<div class="nav-form ">
    <div class="nav-content">
        <div class="nav-spec">
            <nav class="nav-menu">
                <div class="mobile-menu nav-is-visible"><span></span></div>
                <div class="menu-main-menu-container"><ul id="primary-menu" class="menu"><li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home menu-item-513"><a href="https://www.tr3spsicologia.centelica.com/">Inicio</a><span class="arrow"></span></li>
                <li class="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-505 current_page_item menu-item-510"><a href="https://www.tr3spsicologia.centelica.com/about-us/" aria-current="page">Quiénes Somos</a><span class="arrow"></span></li>
                <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-753"><a href="https://www.tr3spsicologia.centelica.com/servicios/">Servicios</a><span class="arrow"></span></li>
                <li class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-715"><a href="#">ÁREA EDUCATIVA</a><span class="arrow"></span>
                <ul class="sub-menu">
                <li class="menu-item menu-item-type-taxonomy menu-item-object-course_category menu-item-780"><a href="https://www.tr3spsicologia.centelica.com/course-category/cursos/">CURSOS</a><span class="arrow"></span></li>
                <li class="menu-item menu-item-type-taxonomy menu-item-object-course_category menu-item-781"><a href="https://www.tr3spsicologia.centelica.com/course-category/diplomados/">DIPLOMADOS</a><span class="arrow"></span></li>
                <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-810"><a href="https://www.tr3spsicologia.centelica.com/proximamente/">TALLERES Y PLÁTICAS</a><span class="arrow"></span></li>
                <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-811"><a href="https://www.tr3spsicologia.centelica.com/proximamente/">MATERIAL EDUCATIVO</a><span class="arrow"></span></li>
                <li class="menu-item menu-item-type-custom menu-item-object-custom menu-item-655"><a href="/proximamente/">EGRESADOS</a><span class="arrow"></span></li>
                </ul>
                </li>
                <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-508"><a href="https://www.tr3spsicologia.centelica.com/contact-us/">Contacto</a><span class="arrow"></span></li>
                </ul></div>                
            </nav>
        </div>
    </div>
</div>

<script type='text/javascript' id='education-lms-custom-js-extra'>
/* <![CDATA[ */
var Education_LMS = {"menu_sidebar":"left","sticky_header":"0"};
/* ]]> */
</script>
<script type='text/javascript' src='../../../../assets/js/custom.js' id='education-lms-custom-js'></script>